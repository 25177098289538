<script>
import feather from 'feather-icons';
import Button from './reusable/Button.vue';
import FormInput from './reusable/FormInput.vue';
import FormTextarea from './reusable/FormTextarea.vue';
export default {
	props: ['showModal', 'modal', 'categories'],
	components: { Button, FormInput, FormTextarea },
	data() {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {
		scrollToBottom() {
			this.$nextTick(() => {
				const modalBody = document.querySelector('.modal-body');
				if (modalBody) {
					modalBody.scrollTop = modalBody.scrollHeight;
				}
			});
		}
	},
	watch: {
		modal(newVal) {
			if (newVal) {
				this.scrollToBottom();
			}
		}
	}
};
</script>

<template>
	<transition name="fade">
		<div v-show="modal" class="fixed inset-0 z-30 font-general-regular">
			<!-- Modal body background as backdrop -->
			<div
				v-show="modal"
				@click="showModal()"
				class="fixed inset-0 z-20 w-full h-full bg-black bg-opacity-50 bg-filter"
			></div>
			<!-- Modal content -->
			<main
				class="flex flex-col items-center justify-center w-full h-full"
			>
				<transition name="fade-up-down">
					<div
						v-show="modal"
						class="z-30 flex items-center modal-wrapper"
					>
						<div
							class="relative flex-row max-w-md max-h-screen mx-5 rounded-lg shadow-lg modal md:max-w-xl bg-secondary-light dark:bg-primary-dark"
						>
							<div
								class="flex justify-between gap-10 p-5 border-b modal-header border-ternary-light dark:border-ternary-dark"
							>
								<h5
									class="text-xl text-primary-dark dark:text-primary-light"
								>
									What project are you looking for?
								</h5>
								<button
									class="px-4 text-primary-dark dark:text-primary-light"
									@click="showModal()"
								>
									<i data-feather="x"></i>
								</button>
							</div>
							<div class="w-full h-full p-5 modal-body">
								<form class="max-w-xl m-4 text-left">
									<FormInput
										label="Full Name"
										inputIdentifier="name"
										class="mb-2"
									/>
									<FormInput
										label="Email"
										inputIdentifier="email"
										inputType="email"
									/>

									<div class="mt-6 mb-4">
										<label
											class="block mb-2 text-lg text-primary-dark dark:text-primary-light"
											for="project"
											>Project Type</label
										>
										<select
											class="w-full px-5 py-3 border-gray-200 rounded-md border-1 dark:border-secondary-dark text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
											id="project"
											name="project"
											type="text"
											required=""
											aria-label="Project Category"
										>
											<option
												v-for="category in categories"
												:key="category.id"
												:value="category.value"
											>
												{{ category.name }}
											</option>
										</select>
									</div>

									<FormTextarea
										label="Details"
										textareaIdentifier="details"
									/>

									<div class="pb-4 mt-7 sm:pb-1">
										<Button
											title="Send Request"
											class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-indigo-500 hover:bg-indigo-600 rounded-md focus:ring-1 focus:ring-indigo-900 duration-500"
											type="submit"
											aria-label="Submit Request"
										/>
									</div>
								</form>
							</div>
							<div
								class="px-8 py-5 mt-2 text-right modal-footer sm:mt-0 border0-t"
							>
								<Button
									title="Close"
									class="px-4 py-2 duration-500 bg-gray-600 rounded-md sm:px-6 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light focus:ring-1 focus:ring-indigo-900"
									@click="showModal()"
									aria-label="Close Modal"
								/>
							</div>
						</div>
					</div>
				</transition>
			</main>
		</div>
	</transition>
</template>

<style scoped>
.modal-body {
	max-height: 570px;
}
.bg-gray-800-opacity {
	background-color: #2d374850;
}
@media screen and (max-width: 768px) {
	.modal-body {
		max-height: 400px;
	}
}
.fade-up-down-enter-active {
	transition: all 0.3s ease;
}
.fade-up-down-leave-active {
	transition: all 0.3s ease;
}
.fade-up-down-enter {
	transform: translateY(10%);
	opacity: 0;
}
.fade-up-down-leave-to {
	transform: translateY(10%);
	opacity: 0;
}

.fade-enter-active {
	-webkit-transition: opacity 2s;
	transition: opacity 0.3s;
}
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
