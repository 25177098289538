<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'Yoshio Sudewa',
			author: 'Stoman',
		};
	},
};
</script>

<template>
	<div class="flex items-center justify-center text-center">
		<div
			class="text-lg font-general-regular text-ternary-dark dark:text-ternary-light"
		>
			&copy; {{ copyrightDate }}.
			<a
				href="https://yoshiosudewa.my.id"
				target="__blank"
				class="duration-500 font-general-medium hover:underline hover:text-indigo-600 dark:hover:text-indigo-300"
			>
				{{ projectName }}
			</a>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
